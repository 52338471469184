<div *ngIf='showButton$ | async'
     class='iosimplus-button'
     [ngSwitch]='currentSimulationStatus'>

    <ng-template [ngSwitchCase]='SimulationStatusEnum.InProgress'>
        <div class='btn btn-default state-progress'
             [style.background-position-x]='backgroundProgress'>
            <div #content
                 class='btn-content'
                 [style.padding-right]='progressHorizontalPaddingInPx'
                 [style.padding-left]='progressHorizontalPaddingInPx'>
            <span #caption
                  class='caption'
                  [style.background-position-x]='captionProgress'>{{'IOSimPlus.LaunchButton' | translate}}</span>
            </div>
            <div class='flare'></div>
        </div>
    </ng-template>

    <ng-template ngSwitchDefault>
        <button class='btn btn-primary m-0'
                id="iosim-btn"
                [ngClass]="{
                    'state-with-icon': isCurrentStateHasIcon || !isQualifiedForIoSimPlus,
                    'ready-icon': isReadyIconShouldBeShown,
                    'failed-icon': isFailedIconShouldBeShown || !isQualifiedForIoSimPlus
                }"
                [attr.disabled]="isButtonShouldBeDisabled ? 'disabled' : null"
                (click)='onButtonClick()'>
            <span>{{'IOSimPlus.LaunchButton' | translate}}</span>
        </button>
    </ng-template>
</div>
<eup-status-info-modal id='status-info-modal'
                       [orderCode]='order.orderCode' 
                       [orderId]="order.id"
                       [viewedScreen]="viewedScreen"
                       [isQualifiedForIoSimPlus]="isQualifiedForIoSimPlus">
</eup-status-info-modal>
