import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BaseDestroyable } from '@core/base-destroyable';
import { IOSimSimulationInfoQuery } from '../../../services/iosim-simulation.store/iosim-simulation-status-progress.query';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { StatusInfoModalComponent } from '@shared/iosim-plus/iosim-plus-button/status-info-modal/status-info-modal.component';
import { SimulationStatusEnum, SimulationStatusProgress } from '../models/simulationStatus';
import { IOSimPlusIntegrationService } from 'app/services/iosim-plus-integration/iosim-plus-integration.service';
import { ProgressState } from '@shared/iosim-plus/iosim-plus-button/progress.state';
import { NowProvider } from '@shared/iosim-plus/iosim-plus-button/nowProvider';
import { Order } from 'app/doctors/orders/orders.service';
import { IOSimSimulationInfo } from 'app/services/iosim-simulation.store/iosim-simulation-status-progress-state.model';
import { IOSimSimulationStatusProgressService } from 'app/services/iosim-simulation.store/iosim-simulation-status-progress.service';

@Component({
	selector: 'eup-iosim-plus-button',
	templateUrl: './iosim-plus-button.component.html',
	styleUrls: ['./iosim-plus-button.component.scss'],
})
export class IOSimPlusButtonComponent extends BaseDestroyable implements OnInit, OnDestroy {
	SimulationStatusEnum = SimulationStatusEnum;

	private readonly progressHorizontalPadding = 26;
	private readonly statesWithIcon = [SimulationStatusEnum.Ready, SimulationStatusEnum.Fail, SimulationStatusEnum.LowQualityScan];
	private readonly statesWithReadyIcon = [SimulationStatusEnum.Ready];
	private readonly statesWithFailedIcon = [SimulationStatusEnum.LowQualityScan, SimulationStatusEnum.Fail];
	private readonly statesWithDisabledButton = [SimulationStatusEnum.None, SimulationStatusEnum.InProgress];
	private readonly nowProvider: NowProvider = new NowProvider();
	private readonly progressState: ProgressState = new ProgressState(this.nowProvider);

	@ViewChild('content', { static: false })
	contentContainer: ElementRef;
	@ViewChild('caption', { static: false })
	captionContainer: ElementRef;
	@ViewChild(StatusInfoModalComponent)
	statusInfoModal: ModalDirective;

	@Input()
	order: Order;
	@Input()
	returnUrl: string;
	@Input()
	viewedScreen: string;

	backgroundProgress: string;
	captionProgress: string;
	showButton$: Observable<boolean>;
	currentSimulationStatus = SimulationStatusEnum.None;
	warningContent = '';
	isButtonBusy = false;

	private _progressPercents = 50;

	get progressPercents() {
		return this._progressPercents;
	}

	set progressPercents(progress: number) {
		this._progressPercents = progress;
		const percentageBase = 100;

		this.backgroundProgress = `${percentageBase - this.progressPercents}%`;

		const relativeFraction = this.progressPercents / percentageBase;
		let captionPercents =
			!this.contentContainer || !this.captionContainer
				? 0
				: // tslint:disable-next-line:max-line-length
					Math.round(
						((relativeFraction * this.contentContainer.nativeElement.offsetWidth - this.progressHorizontalPadding) /
							this.captionContainer.nativeElement.offsetWidth) *
							percentageBase
					);

		captionPercents = Math.max(0, captionPercents);
		captionPercents = Math.min(captionPercents, percentageBase);

		this.captionProgress = `${percentageBase - captionPercents}%`;
	}

	get progressHorizontalPaddingInPx() {
		return `${this.progressHorizontalPadding}px`;
	}

	get isCurrentStateHasIcon() {
		return this.statesWithIcon.includes(this.currentSimulationStatus);
	}

	get isButtonShouldBeDisabled() {
		return this.statesWithDisabledButton.includes(this.currentSimulationStatus);
	}

	get isReadyIconShouldBeShown() {
		return this.statesWithReadyIcon.includes(this.currentSimulationStatus);
	}

	get isFailedIconShouldBeShown() {
		return this.statesWithFailedIcon.includes(this.currentSimulationStatus);
	}
	get isQualifiedForIoSimPlus() {
		return this.order.isQualifiedForIoSimPlus==undefined || this.order.isQualifiedForIoSimPlus;
	}
	
	constructor(
		private simulationStatusProgressQuery: IOSimSimulationInfoQuery,
		private simulationStatusProgressService: IOSimSimulationStatusProgressService,
		private integrationService: IOSimPlusIntegrationService
	) {
		super();
	}

	ngOnInit() {
		this.initialize();
	}

	ngOnDestroy() {
		this.integrationService.dettachIOSimPlusStatusListener(this.order.id.toString());
		this.progressState.reset();
		super.ngOnDestroy();
	}

	async onButtonClick() {
		if (this.isButtonBusy) {
			return;
		}

		try {
			this.isButtonBusy = true;
			await this.performAction();
		} finally {
			this.isButtonBusy = false;
		}
	}

	private async performAction() {
		if(!this.isQualifiedForIoSimPlus){
			this.statusInfoModal.show();
			return;
		} 
		switch (this.currentSimulationStatus) {
			case SimulationStatusEnum.Ready:
				await this.integrationService.openIOSimPlus(this.order.orderCode, this.order.id.toString(), this.returnUrl);
				break;
			case SimulationStatusEnum.NotStarted:
				await this.integrationService.startSimulation(this.order.id.toString(), this.order.orderCode, this.viewedScreen);
				break;
			case SimulationStatusEnum.LowQualityScan:
			case SimulationStatusEnum.Fail:
				this.statusInfoModal.show();
				break;
		}
		
	}

	private initialize() {
		this.progressState.progressChanged$
			.pipe(
				takeUntil(this.componentAlive$)
			)
			.subscribe(progress => this.progressPercents = progress);

		this.showButton$ = this.integrationService.isIOSimPlusAllowed$;

		this.integrationService.isIOSimPlusAllowed$
			.pipe(
				tap(isIOSimPlusAllowed => this.initializeSimulationStatusProgress(isIOSimPlusAllowed)),
				filter(isIOSimPlusAllowed => isIOSimPlusAllowed),
				tap(() => this.integrationService.attachIOSimPlusStatusListener(this.order)),
				switchMap(_ => this.subscribeToStore()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private initializeSimulationStatusProgress(isIOSimPlusAllowed: boolean) {
		if (!isIOSimPlusAllowed) {
			return;
		}

		const orderId = this.order.id.toString();
		if (this.simulationStatusProgressQuery.hasEntity(orderId)) {
			return;
		}

		const { progress } = this.order.simulationInfo;
		const simulationStatus: IOSimSimulationInfo = {
			progress: {
				simulationStatus: progress.simulationStatus,
				expectedDuration: progress.expectedDuration || IOSimPlusIntegrationService.defaultExpectedDuration,
				startSimulationTime: progress.startSimulationTime,
			},
			orderId,
			notSupportedScanDate: this.order.simulationInfo.notSupportedScanDate,
		};

		this.simulationStatusProgressService.addOrUpdateSimulationStatusProgress(simulationStatus);
	}

	private subscribeToStore(): Observable<SimulationStatusProgress> {
		const shutdown$ = (progress: SimulationStatusProgress, progressState: ProgressState) => of(progress).pipe(
			tap(_ => this.progressState.shutdown()),
			switchMap(_ => this.progressState.release$.pipe(take(1))),
			tap(_ => progressState.reset()),
			map(_ => progress)
		);

		return this.simulationStatusProgressQuery
			.selectProgress(this.order.id.toString())
			.pipe(
				switchMap(progress => {
					if (this.isStateHasIcon(progress.simulationStatus)) {
						if (this.currentSimulationStatus === SimulationStatusEnum.InProgress) {
							return shutdown$(progress, this.progressState).pipe(take(1));
						}

						this.progressState.reset();
					}
					return of(progress);
				}),
				tap(progress => this.currentSimulationStatus = progress.simulationStatus),
				filter(progress => progress.simulationStatus === SimulationStatusEnum.InProgress),
				tap(progress => this.setButtonState(progress))
			);
	}

	private setButtonState(simulationProgress: SimulationStatusProgress) {
		this.progressState.setProgress(simulationProgress);
		this.progressState.startIfRequired();
	}

	private isStateHasIcon(state: SimulationStatusEnum) {
		return this.statesWithIcon.includes(state);
	}
}
