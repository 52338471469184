import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, AppSettings } from '../../shared/generalInterfaces';
import { akitaDevtools } from "@datorama/akita";

@Injectable({
	providedIn: 'root'
})
export class AppConfigService {

	private appConfig: AppConfig = new AppConfig();
	private appconfigRoute: string = '/assets/config/appSettings.json';
	private appconfigRouteMock: string = '/assets/config/appSettingsMock.json';

	constructor(private http: HttpClient) { }

	async loadAppConfig(): Promise<void> {

		const urlParams = new URLSearchParams(window.location.search);
		const useMock = urlParams.get('usemock')
		const response = await fetch(useMock ? this.appconfigRouteMock : this.appconfigRoute);
		const data = await response.json() as AppSettings;

		this.appConfig.appSettings = data;

		// Place here all that needed to development.
		if (!data.isProduction) {
			akitaDevtools();
		}
	}

	isValidConfig = (key: string) => {
		const value = this.appConfig?.appSettings[key];
		if (value == undefined || value == null || value.toString() == '' || value.toString().toLowerCase().includes('replaceme')) {
			return false;
		}
		return true;
	}

	get appSettings(): AppSettings {
		if (!this.appConfig.appSettings) {
			throw Error('App Settings not loaded!');
		}

		return this.appConfig.appSettings;
	}
}