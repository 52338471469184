import {IShellEvent} from './IShellEvent';
import {ShellCommunicationEvents} from '@shared/generalInterfaces';
import {map, mergeMap, take} from 'rxjs/operators';
import {ShellContextService} from '../../shell-context/shell-context.service';
import {Injectable} from '@angular/core';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { FeatureToggle } from '@shared/enums';
import { IShellContext, PlatformServiceNames } from '@shared/store/IShellContext';
import { of } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ShellGetContextEvent implements IShellEvent {

	constructor(private shellContextService: ShellContextService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService) {
	}

	get eventName(): string {
		return ShellCommunicationEvents.GetContext;
	}

	handleCustomEvent(event): void {

	}

	handlePostMessage(event, callback): void {
		const initEventName = ShellCommunicationEvents.InitContext.replace('{0}', event.appName);

		this.shellContextService.getContext().pipe(
			take(1),
			mergeMap(ctx => {
				if(event.appName === 'web-3d-tool') {
					return this.featuresToggleSettingsService.getAll().pipe(map(flags => ({ctx: ctx, mapToNewStructure: flags.find(f => f.id === FeatureToggle.PublishNewContextSchemaToViewer)?.isActive === true})))
				}
				return of({ctx: ctx, mapToNewStructure: false});
			}))
			.subscribe(context => {
				const message = {
					eventName: initEventName,
					appName: 'shell',
					payload: context.mapToNewStructure ? context.ctx : this.mapToOldStrcture(context.ctx)
				};

				callback(event.appName, message);
			});
	}

	mapToOldStrcture(context: IShellContext) {
		return {
			accessToken: context.security.accessToken,
			csmMessageId: context.bizCtx.csmMessageId,
			dateFormat: context.UI.dateFormat,
			loggedInUserId: context.user.loggedInUserId,
			orderId: context.bizCtx.orderId,
			patientId: context.bizCtx.patientId,
			refreshTokenUrl: context.platformServices.find(x => x.name === PlatformServiceNames.SessionManagementRefresh)?.url,
			region: context.bizCtx.region,
			roleType: context.user.roleType,
			rxId: context.bizCtx.rxId,
			selectedCompanyId: context.bizCtx.companyId,
			selectedDoctorId: context.bizCtx.contactId,
			selectedLanguage: context.UI.language,
			sessionId: context.session.sessionId,
			sessionType: context.session.sessionType
		};
	}
}
