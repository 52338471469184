import { Component, OnInit, ViewChild, Input, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { RouterInterceptorService } from '../../core/routerInterceptor.service';
import { SettingsModal } from '../../settings/settings.component';
import { Utils } from '../utils.service';
import { GlobalSettingsService, ContextParams, GlobalSettings } from '../../core/globalSettings.service';
import { ChangeTitlePayload, Company, Contact } from '../generalInterfaces';
import { AccessibilityService } from '../accessibility.service';
import { DownloadNotificationComponent } from '../downloadNotification/downloadNotification.component';
import { AuthService } from 'app/services/authentication/auth.service';
import { LocalStorageService } from '@core/localStorage.service';
import { Consts } from '@shared/consts';
import { SoftwareOptionsForCompany, FeatureToggle } from '@shared/enums';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { UserSettingsService } from '../../services/userSettings/userSettings.service';
import { TimberService } from '@logging/timber.service';
import { BiAction, BiLocationObject, BiObjectType } from '@logging/interfaces/bi.enums';
import { PracticeManagementAccessibilityService } from '@shared/practice-management-accessibility.service';
import { StickyHeaderService } from '../../services/stickyHeaderService/stickyHeader.service';
import { RxService } from 'app/rx/services/rx.service';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { ExportRequest, OrthoCadExportModal } from '@shared/orthoCadExport/orthoCadExport.component';
import { RestorativeExportModal } from '@shared/restorativeExport/restorativeExport.component';
import { BaseDestroyable } from '@core/base-destroyable';
import { Order } from 'app/doctors/orders/orders.service';
import { ApplicationNavigationService } from 'app/services/shell/application-navigation/application-navigation.service';


@Component({
	selector: 'eup-sticky-header',
	templateUrl: './stickyHeader.component.html',
	styleUrls: ['./stickyHeader.component.scss']
})

export class StickyHeaderComponent extends BaseDestroyable implements OnInit, OnDestroy {
	public static readonly homeImage = 'home46';
	public static readonly homeLink = 'home';
	private readonly backImage = 'backButton46';
	private rolePath: string;
	private storage: Storage;
	isLogoutOpen = false;
	isSettingsMenuOpen = false;
	isLinksMenuOpen = false;
	isDoctorsMenuOpen = false;
	isCompaniesMenuOpen = false;
	enableLabConnect = false;
	enableAccountManagement = false;
	enablePracticeManagement = false;
	isPreSelectCompany = false;
	companies: Company[];
	filteredCompanies: Company[];
	doctors: Contact[];
	selectedCompany: Company;
	selectedDoctor: Contact;
	titleLogo: string = '';
	shouldEnableCaptureTool = false;
	shouldEnableDownloadScreenshots = false;
	shouldShowCaptureTool: boolean;
	isDrawingToolAllowed: boolean;
	showSeparator = false;

	homeLinkClickSubscription: Subscription;
	notificationSub: Subscription;
	screenshotsSub: Subscription;
	downloadScreenshotSub: Subscription;
	contextChangeSub: Subscription;

	searchCompanyText = '';
	showCompaniesFiltering = false;
	enableAnalytics = false;
	analyticsMidcFf = false;
	analyticsMatFf = false;
	window: any;
	routeHasLogin = false;
	isChatBotVisible = false;
	isChatBotFFEnabled = false;
	isLabConnectFFEnabled = false;
	isAmUiEnabled = false;
	order: Order;

	@Input() titleText = '';
	dynamicTitleText = this.titleText;
	@Input() image = StickyHeaderComponent.homeImage;
	@Input() backLink = StickyHeaderComponent.homeLink;
	@Input() backToLastPage = null;
	@Input() disableContactSelection = false;
	@Input() disableDoctorSelection = false;
	@Input() hideContactSelection = false;
	@Input() isLongTitle = false;
	@Input() uponHomeLinkClickCallback: Function;
	@Input() homeLinkClickEvent: EventEmitter<any>;
	@Input() disableSiteNavigation = 'false';
	@Input() isBackLinkAbsoluteUrl = 'false';
	@Input() shouldAddRoleToBackLink = 'true';
	@Input() isVerticalMenu = false;
	@Input() insideContent = false;
	@Input() showScreenshots = false;
	@Input() showDownloadScreenshots = false;
	@Input() showExport = false;
	@Input() backIcon = false;
	
	@ViewChild(SettingsModal) settingsModal: SettingsModal;
	@ViewChild(DownloadNotificationComponent) downloadNotification: DownloadNotificationComponent;
	@ViewChild(OrthoCadExportModal) orthoCadExportModal: OrthoCadExportModal;
	@ViewChild(RestorativeExportModal) restorativeExportModal: RestorativeExportModal;

	constructor(
		private router: Router,
		public globalSettingsService: GlobalSettingsService,
		private utils: Utils,
		private routerInterceptor: RouterInterceptorService,
		private eupRoutesService: EupRoutesService,
		private route: ActivatedRoute,
		private accessibilityService: AccessibilityService,
		private authService: AuthService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		localStorageService: LocalStorageService,
		public softwareOptionsService: SoftwareOptionsService,
		private appConfigService: AppConfigService,
		private userSettingsService: UserSettingsService,
		private timberService: TimberService,
		private labConnectAccessibilityService: PracticeManagementAccessibilityService,
		private stickyHeaderService: StickyHeaderService,
		private rxService: RxService,
		private shellContextService: ShellContextService,
		private applicationNavigationService: ApplicationNavigationService,
	) {
		super();
		this.storage = localStorageService.storage;
	}

	get hasFullOrdersVisibility(): boolean {
		return this.settings?.hasFullOrdersVisibility;
	}

	get username(): string {
		return this.settings?.username;
	}

	get disableSiteNavigationFunc(): boolean {
		return this.disableSiteNavigation === 'true';
	}

	get isBackLinkAbsoluteUrlFunc(): boolean {
		return this.isBackLinkAbsoluteUrl === 'true';
	}

	get shouldAddRoleToBackLinkFunc(): boolean {
		return this.shouldAddRoleToBackLink === 'true';
	}

	get isCompanyDropdownDisabled(): boolean {
		return this.disableContactSelection || this.companies.length === 1;
	}

	get isDoctorDropdownDisabled(): boolean {
		return !this.hasFullOrdersVisibility || this.disableContactSelection || this.doctors.length === 1 || this.disableSiteNavigationFunc || this.disableDoctorSelection;
	}
	private get settings(): GlobalSettings {
		return this.globalSettingsService.get();
	}

	onTakeScreenshot() {
		this.stickyHeaderService.triggerScreenshot();
	}

	clickAnalytics() {
		const currentPage = window.location.pathname;
		this.timberService.userActionEvent({
			locationParentObject: currentPage,
			objectName: 'AnalyticsButton',
			action: BiAction.Clicked,
			objectType: BiObjectType.Button
		});
		if (currentPage != '/analytics') {
			this.timberService.uiNavigationEvent({sourceName: currentPage, targetName: BiLocationObject.Analytics});
		}
		this.router.navigateByUrl('/analytics');
	}

	toggleLogoutMenu() {
		this.isLogoutOpen = !this.isLogoutOpen;
	}

	hideLogoutMenu() {
		this.isLogoutOpen = false;
	}

	toggleSettingsMenu() {
		this.isSettingsMenuOpen = !this.isSettingsMenuOpen;
	}

	hideSettingsMenu() {
		this.isSettingsMenuOpen = false;
	}

	toggleLinksMenu() {
		this.isLinksMenuOpen = !this.isLinksMenuOpen;
	}

	hideLinksMenu() {
		this.isLinksMenuOpen = false;
	}

	ngOnDestroy(): void {
		this.utils.clearObservablesSubscriptions(this);
		this.titleLogo = '';
		this.titleText = '';
		this.stickyHeaderService.resetSubjects();
		super.ngOnDestroy();
	}
	
	hideDoctorMenu(): void {
		this.isDoctorsMenuOpen = false;
	}

	hideCompaniesMenu(): void {
		this.isCompaniesMenuOpen = false;
	}

	hideDoctorAndCompaniesMenu(): void {
		this.isDoctorsMenuOpen = false;
		this.isCompaniesMenuOpen = false;
	}

	companiesMenuToggled(isOpened: boolean): void {
		if (isOpened) {
			this.hideLinksMenu();
			this.hideLogoutMenu();
			this.downloadNotification.onClose();
		}
		this.clearCompaniesSearch(isOpened);
		this.isCompaniesMenuOpen = isOpened;
	}

	doctorsMenuToggled(isOpened: boolean): void {
		if (isOpened) {
			this.hideLinksMenu();
			this.hideLogoutMenu();
			this.downloadNotification.onClose();
		}

		this.isDoctorsMenuOpen = isOpened;
	}

	ngOnInit(): void {
		this.shellContextService.getOrder().pipe(takeUntil(this.componentAlive$),
			filter(order => !!order),
			tap(order => {
				this.order = order;
				this.shouldEnableDownloadScreenshots = order.isCaptureFileExist;
		})).subscribe();

		const selectedCompanyId = +this.settings.selectedCompanyId;
		this.companies = this.settings.companies;
		this.filteredCompanies = this.settings.companies;
		this.rolePath = this.globalSettingsService.rolePath();
		this.showCompaniesFiltering = this.companies.length >= this.appConfigService.appSettings.minimumCompaniesForFiltering;
		if (!this.isBackLinkAbsoluteUrlFunc && this.shouldAddRoleToBackLinkFunc) {
			this.backLink = this.rolePath + '/' + this.backLink;
		}
		this.selectedCompany = this.getCompanyById(selectedCompanyId);
		this.redirectIfRequired(this.selectedCompany);
		this.fillDoctors(selectedCompanyId);
		this.contextChangeSub = this.globalSettingsService.contextChanged.pipe(takeUntil(this.componentAlive$)).subscribe((params: ContextParams) => {
			if (!params.companyId || !params.doctorId) {
				return;
			}
			this.updateCompany(this.getCompanyById(+params.companyId));
			this.onDoctorChanged(this.getDoctorById(+params.doctorId));
			this.enableAnalytics = this.shouldShowAnalytics();
			this.isChatBotVisible = this.isAdaChatbotFFOrSWOEnabled();
		});
		this.featuresToggleSettingsService.getFeaturesToggleSettings().pipe(takeUntil(this.componentAlive$)).subscribe(() => {
			this.analyticsMidcFf = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.IteroAnalyticsMIDC, false);
			this.analyticsMatFf = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.IteroAnalyticsMAT, false);
			this.isChatBotFFEnabled = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.ChatBot, false);
			this.isLabConnectFFEnabled = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.LabConnectTab, false);
			this.isAmUiEnabled = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.AMUIEnabled, false);
			this.enableAnalytics = this.shouldShowAnalytics();
			this.isChatBotVisible = this.isAdaChatbotFFOrSWOEnabled();
		});
		combineLatest([
			this.labConnectAccessibilityService.labConnectAvailability$,
			this.labConnectAccessibilityService.accountManagementAvailability$
		]).pipe(takeUntil(this.componentAlive$),
			tap(([labConnectAvailability, accountManagementAvailability]) => {
				this.enableLabConnect = labConnectAvailability;
				this.enableAccountManagement = accountManagementAvailability;
				this.enablePracticeManagement = labConnectAvailability || accountManagementAvailability;
			})
		).subscribe();
		this.notificationSub = this.userSettingsService.isNotificationsEnabled.subscribe((isNotificationsEnabled) => {
			this.openNotificationsSettings(isNotificationsEnabled);
		});
		// when this property is set to true - override image and back link
		// according to previous url
		const previousRoute = this.routerInterceptor.previousRoute;
		if (this.backToLastPage === 'true' || this.backToLastPage === true) {
			// if no previous url exists (e.g. user entered url in a new tab)
			// or the previous url is home so back button should link to home page
			// or user was redirected to a page from straight from login
			if (
				!previousRoute ||
				previousRoute === '/' + this.rolePath + '/' + StickyHeaderComponent.homeLink ||
				previousRoute === '/login' ||
				previousRoute.substring(0, previousRoute.indexOf('?')) === '/login' ||
				previousRoute === '/'
			) {
				this.setDefaultLinkAndImage();
			} else {
				// a previous url exists and it is not the home page so back button should link
				// to the last page that the user was at
				this.backLink = previousRoute.slice(1);
				this.image = this.backImage;
			}
		} else if (this.backIcon) {
			this.image = this.backImage;
		}
		 else if (this.backToLastPage === 'false') {
			this.setDefaultLinkAndImage();
		}

		this.preselectCompany();
		this.checkRouteHasLogin(previousRoute);
		this.subscribeToChangeTitle();
		this.subscribeToScreenshotsEnabled();
		this.subscribeToDownloadScreenshotsEnabled();
		this.initTools(selectedCompanyId);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.titleText && changes.titleText.currentValue !== changes.titleText.previousValue) {
			this.dynamicTitleText = this.titleText;
			this.handleLogo();
		}
	}

	handleLogo() {
		const savedLogo = sessionStorage.getItem(Consts.Storage.Logo);

		if (savedLogo != '' && this.applicationNavigationService.stackLength() > 1) {
			this.titleLogo = savedLogo;
			this.showSeparator = true;
		} else {
			this.titleLogo = '';
			this.stickyHeaderService.resetLogo();
			this.showSeparator = false;
		}
	}

	private subscribeToScreenshotsEnabled() {
		this.screenshotsSub = this.stickyHeaderService.screenshotsEnabled$.pipe(
			tap(screenshotsEnabled => {
			  this.shouldEnableCaptureTool = screenshotsEnabled;
		  })).subscribe();
	}

	private subscribeToDownloadScreenshotsEnabled() {
		this.downloadScreenshotSub = this.stickyHeaderService.downloadScreenshotsEnabled$.pipe(
			tap(downloadScreenshotsEnabled => {
			  this.shouldEnableDownloadScreenshots = this.order?.isCaptureFileExist || downloadScreenshotsEnabled;
		  })).subscribe();
	}

	private initTools(companyId: number) {
		this.softwareOptionsService.getSnapshotSoftwareOptions(companyId).pipe(
			takeUntil(this.componentAlive$),
			tap((response) => {
				this.shouldShowCaptureTool = this.showScreenshots && !response?.shouldDisableCapture;
				this.isDrawingToolAllowed = !response?.shouldDisableDrawingTool;
			})
		)
		.subscribe();
	}

	private subscribeToChangeTitle(){
		this.stickyHeaderService.titleChanged$.pipe(
			takeUntil(this.componentAlive$),
			tap((titleInfo: ChangeTitlePayload) => {
				if (titleInfo.title) {
					if (titleInfo.title.includes('<LOGO>') && (titleInfo.logo || titleInfo.logoFallback)) {						
						if (titleInfo.logo) {
							this.adjustTitleText(titleInfo.title);
							this.titleLogo = titleInfo.logo;
							sessionStorage.setItem(Consts.Storage.Logo, titleInfo.logo);
						} else if (titleInfo.logoFallback) {
							this.dynamicTitleText = `${titleInfo.logoFallback} ${titleInfo.title}`;
							this.titleLogo = '';
							this.stickyHeaderService.resetLogo();
						}
					} else {
						this.dynamicTitleText = titleInfo.title.replace('<LOGO>', '');
						this.titleLogo = '';
						this.stickyHeaderService.resetLogo();
					}
				}
			})).subscribe();
	}

	private adjustTitleText(title: string) {
		this.dynamicTitleText = title.replace('<LOGO>', '');
		if(this.dynamicTitleText.includes('|')) {
			this.dynamicTitleText = this.dynamicTitleText.split('|')[1].trim();
			this.showSeparator = true;
		} else {
			this.showSeparator = false;
		}
	}
	
	logout(): void {
		this.authService.logout().subscribe();
	}
	updateCompany(company: Company): void {
		if (this.selectedCompany.id === company.id) {
			return;
		}
		this.redirectIfRequired(company);
		this.selectedCompany = company;
		this.globalSettingsService.setCompanyAndDefaultDoctor(company.id);
		this.fillDoctors(company.id);
		this.initTools(company.id);
	}
	onCompanyChanged(company: Company): void {
		this.isPreSelectCompany = false;
		this.updateCompany(company);
	}
	private openNotificationsSettings(isNotificationsEnabled) {
		 this.route.data?.pipe(
			filter(data => data['openNotificationsSettings'] )
		).subscribe(() => {
				if (this.isPreSelectCompany &&  isNotificationsEnabled) {
						this.isPreSelectCompany = false;
						this.settingsModal.show(this.settingsModal.notificationsSettingsModal);
				}
		});

	}

	onDoctorChanged(doctor: Contact): void {
		if (doctor === undefined || this.selectedDoctor.contactId === doctor.contactId) {
			return;
		}

		this.selectedDoctor = doctor;
		this.globalSettingsService.setSelectedDoctor(this.selectedCompany.id, this.selectedDoctor.contactId).subscribe(_ => {
				this.globalSettingsService.setDoctor(doctor.contactId);
				this.timberService.sessionTagsEvent({tags: {userProfile: doctor.contactId?.toString()}});
			}
		);
	}

	onImageLinkClick(): void {
		if (this.uponHomeLinkClickCallback && this.homeLinkClickEvent) {
			if (!this.homeLinkClickSubscription) {
				this.homeLinkClickSubscription = this.homeLinkClickEvent.subscribe((res: any) => {
					this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
				});
			}
			this.uponHomeLinkClickCallback();
		} else {
			if (this.isBackLinkAbsoluteUrlFunc) {
				window.location.href = this.backLink;
			} else {
				this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
			}
		}
	}
	
	onLearningCenterClick(): void {
		this.clearTransitionStack();
		this.router.navigateByUrl(`/${this.rolePath}/learningcenter`);
	}

	onPracticeManagementLinkClick(): void {
        const currentUrl = this.router.url;
		let link: string;
		if (this.isAmUiEnabled) {
			link = `/mfe-wrapper/${Consts.AccountManagement}`;
			if (!currentUrl.startsWith(link)) {
				this.clearTransitionStack();
				this.router.navigate([link]);
			}
		} else {
			link = this.enableAccountManagement ? '/practice-management/info' : '/practice-management/mylabs';
			this.router.navigate([link]);
		}
    }

	onDownloadScreenshotClick() {
		this.rxService.getExportGalleryFile(this.order.id.toString(), this.order?.patientName);
	}
	
	onExportClick(event: Event) {
		if (this.order != null && this.order.canWebExport) {
			event.stopPropagation();
			event.preventDefault();

			const request: ExportRequest = {
				orderDetailsId: +this.order.lastDetailsId,
				orderId: +this.order.id,
			};

			if (this.order.isOrthoOrder === true) {
				this.orthoCadExportModal.show(this.order.caseTypeId.toString(), request);
			} else {
				this.restorativeExportModal.show(this.order.caseTypeId.toString(), request);
			}
		}
	}

	private clearTransitionStack() {
		const transitionsCount = this.applicationNavigationService.stackLength();
		if (transitionsCount > 0) {
			this.applicationNavigationService.resetTransitionStack();
		}
	}

	private getDoctorById(id: number): Contact {
		return this.doctors.filter((c: Contact) => c.contactId === id)[0];
	}

	private getCompanyById(id: number): Company {
		return this.settings.companies.filter((c: Company) => c.id === id)[0];
	}

	private fillDoctors(companyId: number): void {
		this.doctors = [
			{
				contactId: this.settings.contactId,
				contactName: this.settings.username
			}
		];

		this.selectedDoctor = this.doctors[0];

		if (this.hasFullOrdersVisibility) {
			this.globalSettingsService.getContactsForCompany(companyId).pipe(takeUntil(this.componentAlive$)).subscribe((data: Contact[]) => {
				this.doctors = data;
				if (this.doctors.filter((c: Contact) => c.contactId === +this.settings.selectedDoctorId).length > 0) {
					this.selectedDoctor = this.getDoctorById(+this.settings.selectedDoctorId);
				}
			});
		}
	}

	private preselectCompany() {
		this.route.queryParams.subscribe((params) => {
			if (!params['companyid']) {
				return;
			}
			this.isPreSelectCompany = true;
			this.updateCompany(this.getCompanyById(+params['companyid']));
		});
	}

	private redirectIfRequired(company: Company): void {
		if (!company.handlingEupUrl) {
			return;
		}
		if (company.handlingEupUrl.endsWith('/')) {
			company.handlingEupUrl = company.handlingEupUrl.slice(0, -1);
		}

		const myIteroBffUrl = new URL(this.eupRoutesService.serverUrl);
		const companyMyIteroBffUrl = new URL(company.handlingEupUrl);
		const currentPath = this.accessibilityService.getPathName();
		let currentSearch = this.accessibilityService.getCurrentSearch();

		let url = `${this.accessibilityService.getLocation().origin}/login?returnUrl=${currentPath}`;

		if (this.shouldConcatinateCompany(currentPath)) {
			const matches = currentSearch.match(/companyid=([^&]*)/);
			if (matches && matches.length > 0) {
				currentSearch = currentSearch.replace(matches[0], `companyid=${company.id}`);
			} else {
				currentSearch += `?companyId=${company.id}`;
			}
		}

		url += escape(currentSearch);
		const myiteroComUrl = new URL(url);

		const allowRedirection = myIteroBffUrl.host !== companyMyIteroBffUrl.host;
		if (allowRedirection) {
			this.authService.logout().subscribe(() => {
				this.storage[Consts.Storage.RegionalApiEndpoint] = `${companyMyIteroBffUrl.origin}/`;
				this.accessibilityService.redirectTo(myiteroComUrl.href);
			});
		}
	}

	private setDefaultLinkAndImage() {
		this.backLink = this.rolePath + '/' + StickyHeaderComponent.homeLink;
		this.image = StickyHeaderComponent.homeImage;
	}

	private shouldConcatinateCompany(urlPathName: string): boolean {
		if (urlPathName.indexOf('rx/viewer') > -1) {
			return false;
		} else {
			return true;
		}
	}

	private shouldShowAnalytics(): boolean {
		const isAdmin = this.selectedCompany.currentUserIsAccountAdmin;
		const softwareOptions = this.globalSettingsService.get()?.companySoftwareOptions;
		const res = this.softwareOptionsService.areSoftwareOptionExist([SoftwareOptionsForCompany.iTeroAnalytics], softwareOptions);
		return (isAdmin && this.analyticsMidcFf) && (this.analyticsMatFf || res);
	}

	private searchClicked(event: Event) {
		event.stopPropagation();
	}

	private onSearchCompany(event: Event) {
		if (this.searchCompanyText.length > 0) {
			this.filteredCompanies = this.settings.companies.filter(c => c.name.toLowerCase()
			.includes(this.searchCompanyText.toLowerCase()));
		} else {
			this.filteredCompanies = this.companies;
		}
	}

	private clearCompaniesSearch(isDropdwonOpen: boolean) {
		this.searchCompanyText = !isDropdwonOpen ? '' : this.searchCompanyText;
		this.filteredCompanies = !isDropdwonOpen ? this.companies : this.filteredCompanies;
	}

	private isAdaChatbotFFOrSWOEnabled(): boolean {
		const softwareOptions = this.globalSettingsService.get()?.companySoftwareOptions;
		const chatBotSoftwareOptions = [SoftwareOptionsForCompany.ChatBot];
		const chatbotSwoEnabled = this.softwareOptionsService.areSoftwareOptionExist(chatBotSoftwareOptions, softwareOptions);
		return this.isChatBotFFEnabled || chatbotSwoEnabled;
	}

	private checkRouteHasLogin(previousRoute) {
		if (!!previousRoute && (previousRoute === '/login' || previousRoute.substring(0, previousRoute.indexOf('?')) === '/login')) {
			this.routeHasLogin = true;
		}
	}
}
