import { AuthInfo } from 'app/rx/models/rx-app-input-parameters';
import { filter, map, Observable } from 'rxjs';
import { ExocadBusinessContext, ExocaduiContext } from './exocadMiniAppOptions';
import { EupRoutesService } from '@core/eupRoutes.service';
import { TimberService } from '@logging/timber.service';
import { BiFeatureWrapperService } from '@logging/bi-feature-wrapper.service';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { FeatureToggle } from '@shared/enums';
import { BiLocationObject } from '@logging/interfaces/bi.enums';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class ExocadMiniAppModalService {	
	orderId: number;
	rxId: string;
	exocadInstallerUrl: string;
	ExocadMiniAppInstallerBuilderUrl: string;

	constructor(
		private eupRoutesService: EupRoutesService,
		private timberService: TimberService,
		private biFeatureWrapperService: BiFeatureWrapperService,
		private globalSettings: GlobalSettingsService,
		private shellContextService: ShellContextService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private translateService: TranslateService
	) {
		this.exocadInstallerUrl = this.eupRoutesService.exocadMiniAppInstallerUrl;
	}
	
	public context$: Observable<[AuthInfo, ExocaduiContext, ExocadBusinessContext]> = this.shellContextService.getContext().pipe(
		filter((context) => !!context.session.sessionId && !!context.security.accessToken),
		map((context) => {
			const companyInfo = this.globalSettings.getSelectedCompany();
			const username = this.globalSettings.get().username;
			const authInfo = {
				sessionId: context.session.sessionId,
				accessToken: context.security.accessToken,
				sessionType: context.session.sessionType,
			} as AuthInfo;
			const businessContext = {
				companyId: context.bizCtx.companyId,
				companyName: companyInfo?.name,
				countryId: companyInfo?.countryCode,
				contactId: context.bizCtx.contactId,
				region: context.bizCtx.region,
			} as ExocadBusinessContext;

			const uiContext = {
				language: context.UI.language,
				username: username,
			};
			return [authInfo, uiContext, businessContext];
		})
	);

	onMyDesignAppDownloadInstallerClick() {
		const isActive = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.EnableExocadMiniAppInstallerSSO, false);	
		if (isActive) {
			this.context$.subscribe(([authInfo, uiContext, businessContext]) => {
				const params = {
					rxId: this.rxId,
					orderId: this.orderId,
					SESSION_ID: authInfo.sessionId,
					ACCESS_TOKEN: authInfo.accessToken,
					selectedCompanyId: businessContext.companyId,
					selectedDoctorId: businessContext.contactId,
					selectedLanguage: uiContext.language,
					region: this.eupRoutesService.Region,
					parametersUrl: this.eupRoutesService.exocadMiniApp.getIntegrationParameters(businessContext.companyId),
			  };
				this.eupRoutesService.exocadMiniAppEndpoint(params).subscribe((response) => {
					const url = URL.createObjectURL(response);
					const a = document.createElement('a');
					a.href = url;
					a.download = this.translateService.instant('ExocadMiniAppInstallerBuilderUrl');
					a.target = '_blank';
					document.body.appendChild(a);
					a.click();
					a.remove();
					URL.revokeObjectURL(url);
				});
			});
		} else {
			window.open(this.exocadInstallerUrl, '_blank');
		
		}
		const { userAction: userAction } = this.biFeatureWrapperService.LogDownloadInstallMyDesignAppClicked(BiLocationObject.Orders,this.orderId);		
		this.timberService.userActionEvent(userAction);
	}
}
