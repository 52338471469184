import { Injectable, Compiler } from '@angular/core';
import { Router, NavigationStart, NavigationError, NavigationEnd, ActivatedRoute, Data, NavigationCancel } from '@angular/router';
import { SpinnerService } from './spinner/spinner.service';
import { TitleService } from './title.service';
import { AppearanceService, DEFAULT_THEME } from './appearance.service';
import { EupToastService } from './toastr/eupToast.service';
import { navigationErrorPrefix } from '../../assets/consts';
import { TimberService } from '../services/logging/timber.service';
import { FeatureToggleStore } from 'app/featuresToggleSettings/state/feature-toggle-store';

@Injectable()
export class RouterInterceptorService {
	private prevUrl: string;
	private navigationStartTime: number;

	get previousRoute() {
		return this.prevUrl;
	}

	constructor(
		private router: Router,
		private compiler: Compiler,
		private spinner: SpinnerService,
		private titleService: TitleService,
		private activatedRoute: ActivatedRoute,
		private appearanceService: AppearanceService,
		private eupToastr: EupToastService,
		private timberService: TimberService,
		private featureToggleStore: FeatureToggleStore
	) {}

	initialize(): void {
		this.router.events.subscribe((val: any) => {
			if (val instanceof NavigationStart) {
				this.handleNavigationStart(val);
			}

			if (val instanceof NavigationEnd) {
				this.handleNavigationEnd(val);
				this.featureToggleStore.update({ isNavigationTriggered: true });
			}

			if (val instanceof NavigationError) {
				this.handleNavigationError(val);
			}

			if (val instanceof NavigationCancel) {
				this.handleNavigationCancel(val);
			}
		});
	}

	handleNavigationCancel(event: NavigationCancel) {
		this.timberService.trace('Navigation canceled while navigating to route', {
			module: 'RouterInterceptorService',
			extendedParameters: { Url: event.url, Reason: event.reason },
		});
	}

	handleNavigationStart(event: NavigationStart) {
		this.prevUrl = this.router.url;
		this.navigationStartTime = new Date().getTime();
		this.spinner.start(true);
		this.timberService.trace('Navigation start navigating to route', {
			module: 'RouterInterceptorService',
			extendedParameters: { Url: event.url, NavigationTrigger: event.navigationTrigger, Id: `${event.id}` },
		});
	}

	handleNavigationEnd(event: NavigationEnd) {
		this.spinner.stop();
		this.eupToastr.clearAllToastrs();

		
		// calculate timing
		if (this.navigationStartTime) {
			const timeElapsed = new Date().getTime() - this.navigationStartTime;
		}

		let route = this.activatedRoute;
		while (route.firstChild) {
			route = route.firstChild;
		}
		route.data.subscribe((data: Data) => {
			if (!data['skipTitle']) {
				this.titleService.setTitle(data['title']);
			}

			const theme = data['theme'] || DEFAULT_THEME;
			this.appearanceService.toTheme(theme);
			this.appearanceService.setResponsive(data['isResponsive']);
		});
		this.timberService.trace('Navigation end navigating to route', {
			module: 'RouterInterceptorService',
			extendedParameters: { Url: event.url, urlAfterRedirects: event.urlAfterRedirects, Id: `${event.id}` },
		});
	}

	// if any error happens during changing of routes (session expired / exception)
	// then redirect to login page - angular2 doesn't supply the http status of the response
	// so we can't act accordingly
	handleNavigationError(event: NavigationError) {
		this.spinner.stop();
		// angular cache failed component requests so we have to clear the cache
		// otherwise next time angular will request a component that failed to load
		// will throw exception without even making a request to the server
		this.compiler.clearCache();

		// error in login page - reload current page (angular2 doesn't support reloading components yet)
		if (event.url === '/home' && this.prevUrl === '/login') {
			window.location.reload();
		} else {
			// Remain on current page when error occurs loading new page
			this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
				this.router.navigate([this.prevUrl]);
				throw new Error(`${navigationErrorPrefix}: Cannot navigate to ${event.url} - Unexpected Error`);
			});
		}
	}
}
